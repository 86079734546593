export let SonderBAR = {
    name: "SonderBAR",
    eindruck: [
        "gute Location falls man luttert und es regnen sollte",
        "billiges Bier"
    ],
    bewertung: [
        ["Ambiente", 3, [
            "distanziert",
            "LEDs"
        ]],
        ["Getränkesortiment", 3, [
            "schon wenig",
            "keine Karte",
            "große Longdrinkauswahl"
        ]],
        ["Service", 7, [
            "nett",
            "direkt",
            "keine Karte",
            "Sonderwünsche möglich"
        ]],
        ["Ausstattung", 4, [
            "gute Ausstattung für die Größe",
            "kein Außenbereich"
        ]],
        ["Preis/Leistung", 5, []],
        ["Gesamt-Bewertung",  4, []]
    ],
    politisch: 0,
    sympathisch: 0,
    bierpreis: 75,
    groesse: "klein",
    lage: "mit allen Bahnen erreichbar, direkt an der Lutter",
    raucher: "Außenaschenbecher, drinnen rauchen",
    zeiten: "mal so, mal so",
    events: "Privatveranstaltungen möglich",
    urteil: ""
}