<template>
    <div id="content">
        <Kneipen v-if="pageKneipen"></Kneipen>
        <Kneipe v-if="pageKneipe" :kneipe="kneipe" :id="id" :detailed="true"></Kneipe>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { json } from '@/assets/kneipen.js';
import Kneipen from '@/components/Kneipen.vue';
import Kneipe from "@/components/Kneipe.vue";
import '@/assets/css/main.css';

const uri = new URL(window.location.href);
const queryParams = new URLSearchParams(uri.search);

const pageKneipe = queryParams.get("res") === "kneipe";
const pageKneipen = !pageKneipe;

const id = queryParams.get("id");

const kneipenDaten = ref(json);
const kneipe = kneipenDaten.value[id];

document.title = "Stiftung Kneipentest";
</script>

<style>
html, body, #app {
    justify-content: center;
    display: flex;
    width: 100%;
}
html {
    background-image: url('assets/background.jpeg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 100%;
}
#content {
    width: 95%;
}
@media only screen and (min-width: 768px) {
    #content {
        width: 80%;
    }
}
</style>
