export let ExtraBluesBar = {
    name: "Extra Blues Bar",
    eindruck: [
        "gemütlich",
        "trotz Kellerlage hell",
        "sympathisch wirkendes Klientel",
        "Steckdosen verfügbar"
    ],
    bewertung: [
        ["Ambiente", 6, [
            "gemütlich, schick, rustikal"
        ]],
        ["Getränkesortiment", 6, [
            "klassische Bierkneipe",
            "mannigfaltige Alternativen (auch im nichtalkoholischen Bereich)"
        ]],
        ["Service", 7, [
            "Tischservice"
        ]],
        ["Ausstattung", 5, [
            "gute Auswahl an Beschäftigungsmöglichkeiten",
            "kein Außenbereich",
            "Steckdosen an manchen Tischen"
        ]],
        ["Preis/Leistung", 4, [
            "insgesamt akzeptable Preise"
        ]],
        ["Gesamt-Bewertung", 6, []]
    ],
    politisch: 1,
    sympathisch: 4,
    bierpreis: 90,
    groesse: "groß",
    lage: "nicht mit allen Bahnlinien direkt erreichbar",
    raucher: "kein Außenbereich, aber ein Aschenbecher",
    zeiten: "täglich 19:00-1:00 (auch länger möglich)",
    events: "RoMo, Parteistammtisch (regelmäßig), Konzerte (unregelmäßig)",
    urteil: "gemütliche Bierkneipe, die jeden Tag geöffnet hat, bei akzeptablen Preisen herausragendem Service und guten Beschäftigungsmöglichkeiten"
}