export let PlanB = {
    id: 7,
    name: "Plan B",
    eindruck: [
        "Schönes Ambiente",
        "dunkel",
        "sehr warm"
    ],
    bewertung: [
        ["Ambiente", 6, [
            "dunkel",
            "gebrandete Gläser",
            "schöne Bilder"
        ]],
        ["Getränkesortiment", 6, [
            "geringe Bierqualität",
            "sehr gute Tequila- und Mescaauswahl"
        ]],
        ["Service", 6, [
            "normal halt"
        ]],
        ["Ausstattung", 2, [
            "nix"
        ]],
        ["Preis/Leistung", 1, []],
        ["Gesamt-Bewertung",  4, []]
    ],
    politisch: -2,
    sympathisch: 1,
    bierpreis: 110,
    groesse: "mittel",
    lage: "mittellanger Laufweg vom HBF",
    raucher: "Außenaschenbecher, weites Areal, schwer rauszukommen wenn es voll ist",
    zeiten: "Mi-So 18-open end",
    events: "keine",
    urteil: ""
}