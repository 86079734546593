export let Gegenueber = {
    name: "Gegenüber",
    eindruck: [
        "Achtung: krasse Kickerspieler"
    ],
    bewertung: [
        ["Ambiente", 5, [
            "sehr laut"
        ]],
        ["Getränkesortiment", 5, []],
        ["Service", 3, []],
        ["Ausstattung", 4, []],
        ["Preis/Leistung", 4, [
            "ok",
            "gelegentlich gibt es Aktionstage"
        ]],
        ["Gesamt-Bewertung", 5, []]
    ],
    politisch: 3,
    sympathisch: 3,
    bierpreis: 80,
    groesse: "groß",
    lage: "gut erreichbar mit Bus und Bahn",
    raucher: "Außenaschenbecher, Außenberech teilweise überfüllt",
    zeiten: "Mo-Do 20:00-03:00, Fr-Sa 19:00-05:00",
    events: "Kneipenquiz, Thementage",
    urteil: ""
}