<script setup>
import {defineProps} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faStar as sStar} from "@fortawesome/free-solid-svg-icons";
import '@/assets/css/main.css';

const props = defineProps(['checked']);
</script>

<template>
    <FontAwesomeIcon v-for="n in props.checked" :key="n" :icon="sStar" class="checked"></FontAwesomeIcon>
    <FontAwesomeIcon v-for="n in (7-props.checked)" :key="n" :icon="sStar"></FontAwesomeIcon>
</template>

<style scoped>
.checked {
    color: orange;
}
</style>