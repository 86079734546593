import { ExtraBluesBar } from '@/assets/kneipen/ExtraBluesBar';
import { AltBielefeld } from '@/assets/kneipen/AltBielefeld';
import { Kachelhaus } from '@/assets/kneipen/Kachelhaus';
import { KleineKneipe } from '@/assets/kneipen/KleineKneipe';
import { Gegenueber } from '@/assets/kneipen/Gegenueber';
import { Potemkin } from '@/assets/kneipen/Potemkin';
import { SonderBAR } from '@/assets/kneipen/SonderBAR';
import { PlanB } from '@/assets/kneipen/PlanB';
import { SozCafe } from '@/assets/kneipen/SozCafe';

export let json = [
  ExtraBluesBar,
  AltBielefeld,
  Kachelhaus,
  KleineKneipe,
  Gegenueber,
  Potemkin,
  SonderBAR,
  PlanB,
  SozCafe
]

json.forEach((kneipe, index) => kneipe.id = index);
