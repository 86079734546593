export let AltBielefeld = {
    name:  "Alt-Bielefeld",
    eindruck:    [
        "winzig",
        "Fußball",
        "klassische Fußgängerzonenlokalität",
        "urig"
    ],
    bewertung: [
        ["Ambiente", 3, [
            "insgesamt ganz schick",
            "Außenbereich steht in der Fußgängerzone",
            "familiär"
        ]],
        ["Getränkesortiment", 2, [
            "Getränkevielfalt hält sich in Grenzen",
            "Bierauswahl ist nicht für jeden Geschmack",
            "es gibt Kölsch"
        ]],
        ["Service", 6, [
            "man kann nicht klagen",
            "Getränke werden gebracht"
        ]],
        ["Ausstattung", 3, [
            "Fernseher",
            "bequem sitzbarer Außenbereich",
            "sonst nichts"]],
        ["Preis/Leistung", 2, [
            "3,30 für 0,3 Herforder - WTF?"
        ]],
        ["Gesamt-Bewertung", 3, []]
    ],
    politisch: -4,
    sympathisch: 0,
    bierpreis: 110,
    groesse: "winzig",
    lage: "Fußgängerzone halt, gut mit allen Bahnlinien erreichbar",
    raucher: "Sitzplätze mit Aschenbecher im Außenbereich mit Blick auf TV",
    zeiten: "täglich 11:30-0:00 (vlt. mögl. länger)",
    events: "ka, Fußball (Arminia)",
    urteil: "nette Fußballkneipe für das Bier mit den Eltern nach dem Essen"
}