<script setup>
import { defineProps, ref } from 'vue';
import Plot from "@/components/Plot.vue";
import Stars from "@/components/Stars.vue";
import EditStars from "@/components/EditStars.vue";
import '@/assets/css/main.css';
import '@/assets/css/categorie.css'

const props = defineProps(['id', 'kneipe', 'detailed']);

let checked = ref(0);

fetch(`/api/rating?id=${props.id}`)
    .then(response => response.json())
    .then(data => data.rating)
    .then(ratings => ratings.reduce((a, b) => a + b, 0) / Math.max(ratings.length, 1))
    .then(Math.round)
    .then(rating => checked.value = rating);
</script>

<template>
    <h3>Publikums-Wertung</h3>
    <div class="categorie">
        <span class="name">Bewertung</span>
        <Stars :checked="checked"></Stars>
    </div>
    <div v-if="props.detailed" class="categorie">
        <span class="name">Bewerte selbst</span>
        <EditStars :id="props.id" :kneipe="props.kneipe"></EditStars>
    </div>

    <h3>Klientel</h3>
    <Plot :id="props.id" :kneipe="props.kneipe"></Plot>
</template>

<style scoped>

</style>