<script setup>
import { defineProps } from 'vue';
import { VuePlotly } from '@clalarco/vue3-plotly';
import '@/assets/css/main.css';

const props = defineProps(['id', 'kneipe']);

const layout = {
    height: 500,
    width: 500,
    margin: { t: 50, b: 50, l: 50, r: 50 },
    font: { color: '#000' },
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    xaxis: {
        title: { text: "" },
        range: [ -5.25, 5.25 ],
        fixedrange: true,
        showgrid: false,
        tickmode: 'array',
        tickvals: [-4,4],
        ticktext: ["unsympathisch", "sympathisch"]
    },
    yaxis: {
        title: { text: "" },
        range: [ -5.25, 5.25 ],
        fixedrange: true,
        showgrid: false,
        tickmode: 'array',
        tickvals: [-4,4],
        ticktext: ["unpolitisch", "politisch"],
        tickangle: -90
    },
    legend: {
        y: 0.5,
        yref: 'paper',
        font: { family: 'Arial, sans-serif', size: 20, color: 'grey' }
    }
};
if(document.documentElement.clientWidth < 1280) {
    layout.width = 300;
    layout.height = 300;
    layout.xaxis.tickvals = [-3,3];
    layout.yaxis.tickvals = [-3,3];
}
if(document.documentElement.clientWidth < 400) {
    layout.width = 250;
    layout.height = 250;
}
const trace = {
    x: [props.kneipe.sympathisch],
    y: [props.kneipe.politisch],
    mode: 'markers+text',
    type: 'scatter',
    text: name,
    textposition: 'top center',
    textfont: {family: 'Raleway, sans-serif'},
    marker: {size: 12},
    hoverinfo: 'skip'
};
const data = [trace];
const config = {displayModeBar: false, responsive: true};

// Plotly.newPlot(plot, data, layout, config);
</script>

<template>
    <VuePlotly :data="data" :layout="layout" :config="config" />
    <div :id="'plot-' + props.id" class="plot"></div>
</template>

<style scoped>

</style>