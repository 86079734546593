export let Kachelhaus = {
    name: "Kachelhaus",
    eindruck: [
        "coole Beleuchtung",
        "schicke Bar",
        "ansprechende Architektur"
    ],
    bewertung: [
        ["Ambiente", 5, [
            "schönes Licht, leuchtet schön"
        ]],
        ["Getränkesortiment", 6, [
            "ein Auswahl an gut trinkbarem Bier für jeden Geschmack",
            "gute Auswahl an nicht alkoholischen Getränken",
            "es gibt Kölsch"]],
        ["Service", 7, [
            "wie im Restaurant halt"
        ]],
        ["Ausstattung", 3, [
            "schöner Außenbereich in der Fußǵängerzone",
            "keine zusätzlichen Bespaßungsmöglichkeiten"
        ]],
        ["Preis/Leistung", 3, [
            "etwas teuer, aber noch akzeptabel",
            "für den etwas schickeren Abend"]],
        ["Gesamt-Bewertung", 5, []]
    ],
    politisch: -5,
    sympathisch: 0,
    bierpreis: 96,
    groesse: "groß",
    lage: "siehe Alt-Bielefeld",
    raucher: "solider Außenbereich, Aschenbecher?",
    zeiten: "Mo-Fr ab 8:00, Sa-So ab 9:00 bis so 22:00 (ggf. auch später)",
    events: "keine",
    urteil: "es handelt sich eher um eine gehobene Bar, dennoch sind die Preise akzeptabel; gutes Ambiente"
}