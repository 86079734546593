<script setup>
import { ref } from 'vue';
import { json } from '@/assets/kneipen.js';
import Kneipe from "@/components/Kneipe.vue";
import '@/assets/css/main.css';

const kneipenDaten = ref(json);

const compareBierpreis = (a,b) => a.bierpreis - b.bierpreis;
const compareJuroren = (a,b) => b.bewertung[5][1] - a.bewertung[5][1];
</script>

<template>
    <div id="header">
        <h1>Stiftung Kneipentest</h1>
    </div>
    <div id="main">
        <input type="button" class="button" value="Nach Bierpreis sortieren" @click="kneipenDaten.sort(compareBierpreis)" />
        <input type="button" class="button" value="Nach Bewertung sortieren" @click="kneipenDaten.sort(compareJuroren)" />
        <Kneipe v-for="kneipe in kneipenDaten" :key="index" :kneipe="kneipe" :id="kneipe.id" :detailed="false"></Kneipe>
    </div>
    <div id="footer"></div>
</template>

<style scoped>
#header {
    border-style: solid;
    border-radius: 5px;
    box-shadow: 5px 5px;
    padding: 0 20px;
    margin: 20px 0;
}
#main {
    margin: 20px 0;
}
#footer {
}


 .button {
     background-color: #DDDDDD;
     border-radius: 6px;
     border-style: none;
     box-sizing: border-box;
     color: #000000;
     cursor: pointer;
     display: inline-block;
     font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
     font-size: 14px;
     font-weight: 500;
     height: 30px;
     line-height: 20px;
     list-style: none;
     margin: 0 20px 0 0;
     outline: none;
     padding: 0 16px;
     position: relative;
     text-align: center;
     text-decoration: none;
     transition: color 100ms;
     vertical-align: baseline;
     user-select: none;
     -webkit-user-select: none;
     touch-action: manipulation;
 }
.button:hover,
.button:focus {
    background-color: #EEEEEE;
}
</style>