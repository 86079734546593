export let Potemkin = {
    name: "Potemkin",
    eindruck: [
        "Linke",
        "Sticker",
        "A-Anti-Anticapitalista"
    ],
    bewertung: [
        ["Ambiente", 6, []],
        ["Getränkesortiment", 5, []],
        ["Service", 4, []],
        ["Ausstattung", 6, []],
        ["Preis/Leistung", 6, []],
        ["Gesamt-Bewertung", 5, []]
    ],
    politisch: 5,
    sympathisch: 4,
    bierpreis: 54,
    groesse: "groß",
    lage: "gut erreichbar mit Bus und Bahn",
    raucher: "manchmal drin rauchen, großer Außenbereich, Außenaschenbecher",
    zeiten: "Di-Do 19:30-01:00, Fr-Sa 10:00-03:00, manchmal länger",
    events: "Konzerte (häufig), offen für eigene Veranstaltungen (links)",
    urteil: ""
}