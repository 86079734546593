export let SozCafe = {
    name: "SozCafe",
    eindruck: [
        "Polizeiskelett",
        "Poster"
    ],
    bewertung: [
        ["Ambiente", 7, [
            "sehr geschmackvoll"
        ]],
        ["Getränkesortiment", 2, [
            "schon wenig",
            "gute Limos"
        ]],
        ["Service", 3, [
            "Theke manchmal nicht besetzt"
        ]],
        ["Ausstattung", 7, [
            "Kicker",
            "Indoor Flunken möglich"
        ]],
        ["Preis/Leistung", 7, []],
        ["Gesamt-Bewertung",  5, []]
    ],
    politisch: 4,
    sympathisch: 5,
    bierpreis: 22,
    groesse: "winzig",
    lage: "an der Uni",
    raucher: "geräumige Dachterasse",
    zeiten: "mal so, mal so",
    events: "Unregelmäßig stattfinde Filmabende, Party und ähnliches",
    urteil: "Unschlagbarer Preis, auf jeden Fall empfehlenswert"
}