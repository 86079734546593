<script setup>
import {defineProps, ref} from "vue";
import '@/assets/css/main.css';
import '@/assets/css/article.css';
import '@/assets/css/button.css';

const props = defineProps(['id', 'kneipe']);

let visible = ref(true);

function send() {
    let autor = document.getElementById('autor').value;
    let mascots = ['Meta', 'Lambda', 'Etam', 'Hamilton', 'Niklas Luhmann'];
    autor = autor === '' ? mascots[Math.floor(Math.random() * mascots.length)] : autor;
    let text = document.getElementById('text').value;
    let date = new Date();

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            id: props.id,
            comment: {
                autor: autor,
                datum: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`,
                zeit: `${date.getHours()}:${date.getMinutes()} Uhr`,
                text: text
            }
        })
    };
    fetch('/api/comment', requestOptions)
        .then(console.log);

    visible.value = false;
}
</script>

<template>
    <div v-if="visible" class="article">
        <input id="autor" type="text" placeholder="Name">
        <textarea id="text"></textarea>
        <input id="absenden" class="button" type="button" value="Absenden" @click="send">
    </div>
</template>

<style scoped>
#absenden, #autor, #text {
    display: block;
    margin: 10px 0;
}
#autor {
    width: 180px;
}
#text {
    width: 100%;
    min-height: 100px;
}
</style>