<script setup>
import { defineProps } from 'vue';
import '@/assets/css/main.css';
import '@/assets/css/article.css';

const props = defineProps(['kommentar']);
</script>

<template>
    <div class="article">
        <span class="metainfo">{{ props.kommentar.autor }} schrieb am {{ props.kommentar.datum }} um {{ props.kommentar.zeit }}:</span>
        {{ props.kommentar.text }}
    </div>
</template>

<style scoped>
.metainfo {
    display: block;
    font-size: small;
    font-style: italic;
}
</style>