<script setup>
import {defineProps, ref} from 'vue';
import JurorenWertung from "@/components/JurorenWertung.vue";
import PublikumWertung from "@/components/PublikumWertung.vue";
import Informationen from "@/components/Informationen.vue";
import Kommentar from "@/components/Kommentar.vue";
import NeuerKommentar from "@/components/NeuerKommentar.vue";
import '@/assets/css/main.css';
import '@/assets/css/multicolumn.css';
import '@/assets/css/article.css';

const props = defineProps(['id', 'kneipe', 'detailed']);

function openKneipe(index) {
    if(!props.detailed) {
        window.location.href = `?res=kneipe&id=${index}`;
    }
}

let kommentar = {
    autor: 'ich',
    datum: 'heute',
    zeit: 'jetzt',
    text: 'Ein Kommentar'
}

let kommentare = ref([kommentar]);

fetch(`/api/comment?id=${props.id}`)
    .then(response => response.json())
    .then(data => data.comment)
    .then(comment => kommentare.value = comment);
</script>

<template>
    <div class="article">
        <h2 @click="openKneipe(props.id)">{{ props.kneipe.name }}</h2>
        <div class="multicolumn section">
            <div class="column">
                <JurorenWertung :id="props.id" :kneipe="props.kneipe" :detailed="props.detailed"></JurorenWertung>
            </div>
            <div class="column">
                <PublikumWertung :id="props.id" :kneipe="props.kneipe" :detailed="props.detailed"></PublikumWertung>
            </div>
        </div>
        <div v-if="props.detailed" class="section">
            <Informationen :id="id" :kneipe="kneipe"></Informationen>
        </div>
    </div>

    <div v-if="props.detailed">
        <Kommentar v-for="(kommentar, index) in kommentare" :key="index" :kommentar="kommentar"></Kommentar>
    </div>

    <div v-if="props.detailed">
        <NeuerKommentar :id="props.id" :kneipe="props.kneipe"></NeuerKommentar>
    </div>
</template>

<style scoped>

</style>