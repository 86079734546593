<script setup>
import { defineProps } from 'vue';
import Stars from "@/components/Stars.vue";
import '@/assets/css/main.css';
import '@/assets/css/categorie.css';

const props = defineProps(['id', 'kneipe', 'detailed']);
</script>

<template>
    <h3>Jury-Wertung</h3>
    <div v-if="props.detailed">
        <h4>Erster Eindruck</h4>
        <ul>
            <li v-for="(impression, index) in props.kneipe.eindruck" :key="index">
                {{ impression }}
            </li>
        </ul>
    </div>
    <div>
        <h4 v-if="props.detailed">Bewertung</h4>
        <div v-for="(kategorie, index) in props.kneipe.bewertung" :key="index"
         class="categorie">
            <span class="name">{{ kategorie[0] }}</span>
            <Stars :checked="kategorie[1]"></Stars>
            <ul v-if="props.detailed">
                <li v-for="(kommentar, index) in kategorie[2]" :key="index">
                    {{ kommentar }}
                </li>
            </ul>
        </div>
        <div>
            {{ props.kneipe.urteil }}
        </div>
    </div>
</template>

<style scoped>

</style>