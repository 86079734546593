<script setup>
import {defineProps, ref} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faStar as sStar} from "@fortawesome/free-solid-svg-icons";
import '@/assets/css/main.css';

const props = defineProps(['id', 'kneipe', 'detailed']);

let checked = ref(0);

function click(index) {
    checked.value = index;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: props.id, rating: index })
    };
    fetch('/api/rating', requestOptions)
        .then(console.log);
}
</script>

<template>
    <FontAwesomeIcon v-for="n in checked" :key="n" :icon="sStar" class="checked" @click="click(n)"></FontAwesomeIcon>
    <FontAwesomeIcon v-for="n in (7-checked)" :key="n" :icon="sStar" @click="click(checked+n)"></FontAwesomeIcon>
</template>

<style scoped>
.checked {
    color: orange;
}
</style>