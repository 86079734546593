export let KleineKneipe = {
    name: "Kleine Kneipe",
    eindruck: [],
    bewertung: [
        ["Ambiente", 2, [
            "klassische ranzige Kneipe (Absackerkneipe)",
            "gute Musik"
        ]],
        ["Getränkesortiment", 2, [
            "nur ein Bier vom Fass",
            "große Longdrinkauswahl",
            "guter Melonenvodka"
        ]],
        ["Service", 5, [
            "Tischservice"
        ]],
        ["Ausstattung", 4, [
            "Dart",
            "Spielautomaten",
            "Außenbereich"
        ]],
        ["Preis/Leistung", 3, []],
        ["Gesamt-Bewertung", 3, []]
    ],
    politisch: -5,
    sympathisch: 1,
    bierpreis: 95,
    groesse: "klein",
    lage: "Kesselbrink, Jahnplatz, gut erreichbar",
    raucher: "Außenbereich, Aschenbecher vorhanden",
    zeiten: "So-Do 10:00-01:00, Fr-Sa 10:00-04:00",
    events: "Irish Folk Festival 21.10.23 (Live Musik)",
    urteil: "Absackerkneipe, früher nicht empfehlenswert"
}