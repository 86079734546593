<script setup>
import { defineProps } from 'vue';
import '@/assets/css/main.css';
import '@/assets/css/categorie.css'

const props = defineProps(['id', 'kneipe']);
</script>

<template>
    <div class="categorie">
        <span class="name">Bierpreis</span>
        {{ (props.kneipe.bierpreis/100).toFixed(2) }}€ / 100ml
    </div>
    <div class="categorie">
        <span class="name">Größe</span>
        {{ props.kneipe.groesse }}
    </div>
    <div class="categorie">
        <span class="name">Lage</span>
        {{ props.kneipe.lage }}
    </div>
    <div class="categorie">
        <span class="name">Veranstaltungen</span>
        {{ props.kneipe.events }}
    </div>
    <div class="categorie">
        <span class="name">Öffnungszeiten</span>
        {{ props.kneipe.zeiten }}
    </div>
</template>

<style scoped>

</style>